/* eslint-disable react/no-unknown-property */

const Youtube = ({ source }) => {
  return (
    <div className="plyr__video-embed" id="player">
      <iframe
        src={`${source}?origin=${window.location.origin}&iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default Youtube;
