import React from "react";

const Vimeo = ({ source }) => {
  console.log(source);
  return (
    <div className="plyr__video-embed" id="player">
      <iframe
        src={`${source}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
        allowfullscreen
        // eslint-disable-next-line react/no-unknown-property
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default Vimeo;
