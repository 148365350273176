import React from "react";

const Video = ({ captions, poster, source, qualities, isPremium, ...props }) => {
  // let attr = { "data-poster": poster };
  // if (window.location.pathname.includes("wp-admin")) {
  //   attr = { poster };
  // }
  return (
    <>
      <video id="player" data-poster={poster} src={source} controls {...props}>
        {/* Captions / Subtitles */}
        {isPremium && (
          <>
            {Array.isArray(captions) &&
              captions.map((caption, index) => {
                const info = caption.label.split("/");
                return <track key={index} kind="captions" srcLang={info[1] || " "} label={info[0] || "no label"} src={caption.caption_file} />;
              })}

            {/* Qualities */}
            {!["m3u8", "mpd"].includes(source.split(".").pop()) && (
              <>
                <source src={source} size={720} type="video/mp4" />
                {Array.isArray(qualities) &&
                  qualities.map((item, index) => {
                    return <source key={index} src={item.video_file} size={item.size} type="video/mp4" />;
                  })}
              </>
            )}
          </>
        )}
      </video>
    </>
  );
};

export default Video;
